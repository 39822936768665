@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-primary {
  background: #00a5a5 !important;
}
.text-title {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 130%;
  /* or 83px */
}
.text-subtitle {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  /* or 178% */
  letter-spacing: 0.02em;
}
.text-light {
  color: #ffffff;
}
.text-dark {
  color: #000000;
}
.hero button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 28px;
  gap: 8px;

  width: 157px;
  height: 52px;

  /* secondary */

  background: #b2d235;
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.logo{
  max-width: 320px;
  object-fit: contain;
}

.btn-text {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height, or 178% */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.hero-bg-1 {
  background-image: url(../public/images/hero-1.png);
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
}
.hero-bg-2 {
  background-image: url(../public/images/hero-2.png);
  position: absolute;
  width: 271px;
  height: 271px;
  left: -5px;
  top: 268px;
  background-position: center;
  background-size: cover;
  border: 24px solid #00a5a5;
}

.hero img {
  max-height: 517px;
  max-width: 517px;
  object-fit: cover;
  width: 100%;
}
.section-2 h1 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 140%;
  /* or 67px */

  color: #000000;
  max-width: 547px;
}
.section-2 p {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  /* or 178% */

  text-align: justify;
  letter-spacing: 0.02em;

  color: #000000;

  opacity: 0.7;
  max-width: 547px;
}

.section-2 img {
  object-fit: cover;
  max-width: 540px;
  width: 100%;
}
.experience span {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 140%;
  /* identical to box height, or 56px */

  color: #b2d235;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.expereince p {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height, or 178% */

  letter-spacing: 0.02em;

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.section-3 h1 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 140%;
  /* or 67px */

  text-align: center;

  color: #000000;
}

.section-3 .card {
  background: #fcfcfc;
  border-radius: 16px;
  height: fit-content;
  max-width: 378px;
  width: 100%;
}

.section-3 .card h3 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: 0.02em;

  color: #000000;
}
.section-3 .card p {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  /* or 175% */

  text-align: justify;
  letter-spacing: 0.02em;

  color: #000000;

  opacity: 0.9;
}
.about h1 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 42px;
  /* identical to box height, or 88% */

  text-align: center;

  color: #1e2022;
}
.about {
  background: rgba(178, 210, 53, 0.1);
}
.about p {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 27px;
  /* or 159% */

  text-align: justify;
  letter-spacing: 0.6px;

  color: #1e2022;
}
.section-5 h1 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 140%;
  /* or 67px */
  color: #000000;
}
.contact h3 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: 0.02em;

  color: #000000;
}
.contact p {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height, or 178% */

  letter-spacing: 0.02em;

  color: #000000;

  opacity: 0.5;
}

.content-side {
  max-width: 547px;
}
.footer h1 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 140%;
  /* or 56px */
  color: #ffffff;
  max-width: 586px;
}

.footer h3 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 140%;
  /* identical to box height, or 56px */

  text-align: right;

  color: #b2d235;
}
.footer hr {
  opacity: 0.3;
  border: 1px solid #ffffff;
  width: 100%;
  height: 0px;
  margin-bottom: 25px;
}
.footer p {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  text-align: start;
  letter-spacing: 0.875px;

  color: #ffffff;
}

.navbar button {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  gap: 8px;
  width: 121px;
  height: 44px;

  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 12px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  white-space: nowrap;
}
.navbar svg {
  display: none;
}
.sidebar a {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: 0.02em;

  color: #000000;
  white-space: nowrap;
}
.sidebar a:hover {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: 0.02em;

  color: #b2d235;
}
.sidebar a:active {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: 0.02em;

  color: #b2d235;
}
ol {
  list-style-type: decimal;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: 0.02em;
}
.content p {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  /* or 175% */

  text-align: justify;
  letter-spacing: 0.02em;

  color: #000000;

  opacity: 0.5;
}
.content span {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: 0.02em;

  color: #b2d235;
}
.content h1 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: 0.02em;

  color: #000000;
}
.contact-details img {
  object-fit: cover;
  max-width: 540px;
  width: 100%;
}
@media only screen and (max-width: 640px) {
  .logo{
    max-width: 210px;
    object-fit: contain;
  }
  .text-title {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 130%;
    /* or 47px */

    letter-spacing: 0.02em;

    color: #ffffff;
    /* or 83px */
  }
  .text-subtitle {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    /* or 229% */

    text-align: justify;
    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .navbar button {
    display: none;
  }
  .navbar svg {
    display: block;
  }
  .hero button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 28px;
    gap: 8px;

    width: 157px;
    height: 52px;

    /* secondary */

    background: #b2d235;
    border-radius: 12px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    align-self: center;
  }
  .section-2 img {
    object-fit: cover;
    max-width: 284px;
    width: 90%;
  }
  .hero-bg-2 {
    background-image: url(../public/images/hero-2.png);
    position: absolute;
    width: 148.97px;
    height: 148.97px;
    left: -30px;
    top: 198px;
    background-position: center;
    background-size: cover;
    border: 18px solid #00a5a5;
  }
  .section-2 h1 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    /* identical to box height, or 34px */

    text-align: center;

    color: #000000;
  }
  .section-2 p {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    /* or 229% */

    text-align: justify;
    letter-spacing: 0.02em;

    color: #000000;

    opacity: 0.7;
  }

  .section-2 img {
    object-fit: cover;
    max-width: 284px;
    width: 100%;
    align-self: center;
    margin-top: 74px;
  }
  .section-3 h1 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    /* or 34px */

    text-align: center;

    color: #000000;
  }
  .about h1 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
    /* identical to box height, or 175% */

    text-align: center;

    color: #1e2022;
  }
  .section-5 h1 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
    /* identical to box height, or 175% */

    text-align: center;

    color: #1e2022;
    margin-top: 31px;
  }

  .footer h1 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    /* or 34px */

    color: #ffffff;
  }
  .footer h3 {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;

    text-align: left;

    color: #b2d235;
    margin-top: 50px;
  }
}
